import React from "react";
import Hero from "./Hero";
import Details from "./Details";
import Footer from "../../Maipage.js/Footer";

import "./magic.css"

const Explore = () => {
  return (
    <div>
      <Hero />
      <Details />
      <Footer />
    </div> 
  );
};

export default Explore;
